<template>
  <div class="times-list md:w-1/2 mx-auto fixed-draggable-dynamic-table-wrapper-height semi-box-border-y">

    <draggable-dynamic-table ref="timesListTable"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="showTerm"
                             v-model="data"/>


    <!-- show time prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showClubTimePromptStatus"
      @close="showClubTimePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer flex">
            <div class="mr-2" @click="handleClick('editTimeSettingBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('times.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showClubTimePromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-time @edit="generateTime"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>


    <vs-button id="forceGenerateTimeBTN"
               class="w-full mt-3"
               color="danger"
               v-show="false"
               @click="$refs.forceGenerateTimeConfirmation.showDialog()">
      {{ $t('times.labels.edit') }}
    </vs-button>

    <custom-dialog ref="forceGenerateTimeConfirmation"
                   :title="$t('times.confirmations.edit.title')"
                   :body="$t('times.confirmations.edit.body')"
                   @accept="showClubTimePromptStatus = true"/>

    <button id="insertNewTime" v-show="false" @click="showGenerateDialog"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
  import {getTimes, generateTime} from '@/http/requests/times'
  import {checkUserPermissions} from '../../../../assets/js/functions'
  import InsertTerm from '../../terms/insert/insertTerm'
  import EditTerm from '../../terms/edit/editTerm'
  import TermProfile from '../../terms/profile/termProfile'
  import CustomIcon from '../../../../components/customIcon/customIcon'
  import CustomDialog from "../../../../components/customDialog/customDialog";
  import InsertTime from "../insert/insertTime";

  export default {
    name: 'TimesList',
    metaInfo () {
      return {
        title: this.$t('times.list.title')
      }
    },
    components: {
      InsertTime,
      CustomDialog,
      CustomIcon,
      TermProfile,
      EditTerm,
      InsertTerm,
      DraggableDynamicTable
    },
    data() {
      return {
        showClubTimePromptStatus: false,
        requestSent: false,
        loadingTimer: 0,
        options: {
          id: 'timesListTable',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'endTime',
            i18n: 'times.list.table.header.endTime',
            width: 'calc((100% - 70px) / 2)',
            minWidth: 130,
            align: 'center',
            sortable: true
            // locked: true
          },
          {
            field: 'startTime',
            i18n: 'times.list.table.header.startTime',
            width: 'calc((100% - 70px) / 2)',
            minWidth: 130,
            align: 'center',
            sortable: true
            // locked: true
          },
          {
            field: 'row',
            i18n: 'times.list.table.header.row',
            width: '70px',
            minWidth: 70,
            align: 'center',
            // locked: true,
            // sortable: true,
            // footer: {
              /*type: 'auto-counter'*/
            // }
          }
        ],
        data: [],
        filters: [],
        sorts: ['order[0]=created_at,desc'],
        selectedUser: {},
        page: 1,
        endedList: false,
        actions: [
          {
            toolbar: [
              {
                id: 'insertNewTime',
                icon: 'icon-plus',
                iconPack: 'feather',
                color: 'success',
                permission: ['time.generate', 'setting.update']
              }
            ],
            leftToolbar: [
              {
                id: 'printTable',
                // i18n: 'draggableDynamicTable.actions.print',
                icon: 'icon-printer',
                iconPack: 'feather'
              },
              {
                id: 'downloadTable',
                // i18n: 'draggableDynamicTable.actions.download',
                icon: 'icon-download',
                iconPack: 'feather'
              },
              {
                id: 'settingTable',
                icon: 'icon-settings',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    created() {

      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', ' md:w-1/2 mx-auto')
      }, 50)

      this.getTimes()
    },
    methods: {
      getTimes() {
        if (!this.requestSent) {
          this.requestSent = true

          clearTimeout(this.loadingTimer)
          this.loadingTimer = setTimeout(() => {
            if (!this.endedList) {
              if (this.$refs.timesListTable && this.data.length === 0) {
                this.$refs.timesListTable.loadMoreStatus = 'FirstLoad'
              } else if (this.$refs.timesListTable && this.data.length > 0) {
                this.$refs.timesListTable.loadMoreStatus = 'Loading'
              }

              getTimes(this.page, this.filters, this.sorts).then((res) => {
                this.endedList = res.data.data.length === 0
                res.data.data.forEach((term) => {

                  this.data.push({
                    row: this.data.length + 1,
                    id: term.id,
                    // name: term.name || '',
                    startTime: term.start_time ? term.start_time : '',
                    endTime: term.end_time ? term.end_time : ''
                  })
                })

                setTimeout(() => {
                  if (this.data.length > 0) {
                    this.$emit('timesExist')
                  }
                }, 10)

                if (this.$refs.timesListTable) this.$refs.timesListTable.loadMoreStatus = ''

                this.requestSent = false
              })
                .catch(() => {
                  if (this.$refs.timesListTable) this.$refs.timesListTable.loadMoreStatus = 'Danger'

                  this.requestSent = false
                })
            } else {

              this.requestSent = false
            }
          }, 400)
        }
      },
      showTerm(user) {
        // this.selectedUser = user
        // this.showTermPromptStatus = true
      },
      setFilters(filters) {
        const filters_list = []
        Object.keys(filters).forEach((key) => {
          switch (key) {

            case 'startTime':
              if (filters[key].search.value > 0) filters_list.push(`start_time=${filters[key].search.value}`)
              break

            case 'endTime':
              if (filters[key].search !== '') filters_list.push(`end_time=${filters[key].search}`)
              break
          }
        })

        this.data = []
        this.page = 1
        this.endedList = false
        this.filters = filters_list
        this.getTimes()
      },
      setSort(columns) {
        const sorts_list = []
        Object.keys(columns).forEach((key) => {
          switch (key) {

            case 'row':
              sorts_list.push(`order[0]=id,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'startTime':
              sorts_list.push(`order[0]=start_time,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'endTime':
              sorts_list.push(`order[0]=end_time,${columns[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

        this.data = []
        this.page = 1
        this.endedList = false
        this.sorts = sorts_list
        this.getTimes()
      },
      reloadCourtsList() {
        this.data = []
        this.page = 1
        this.endedList = false
        this.getTimes()
      },
      generateTime() {
        if (checkUserPermissions(['time.generate', 'setting.update']) === 2) {
          const force = this.data.length > 0
          generateTime(force).then((response) => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('times.notifications.insert.success'),
              color: 'success',
              time: 2400,
              icon: 'icon-check',
              iconPack: 'feather'
            })
            this.showClubTimePromptStatus = false
            this.data = []
            setTimeout(() => {
              this.getTimes()
            })
          })
            .catch((error) => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('times.notifications.insert.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
              }
            })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('times.notifications.notAllowed'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        }
      },
      showGenerateDialog() {
        if (this.data.length === 0) {
          this.showClubTimePromptStatus = true
        } else {
          this.handleClick('forceGenerateTimeBTN')
        }
      },
      consoleLog() {
        // console.log(event)
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style scoped>
  .times-list {
    /*height: 100%;*/
  }
</style>
